$dt-black: #000;
$dt-dark-gray: #222;
$dt-dark-gray-blue: #282a2b;
$dt-medium-gray: #67696b;
$dt-medium-dark-gray: #393c3e;
$dt-medium-light-gray: #bbb;
$dt-race-red: #d92e2d;
$dt-blue: #2376d7;
$dt-light-blue: #3483de;
$dt-light-medium-gray: #f4f4f4;
$dt-light-gray: #fafafa;
$dt-nav-light-gray: #e3e3e3;
$dt-warning-yellow: #ffa10d;
$dt-highlight-yellow: #ffeb5a;
$dt-filter-red: #f6f4f4;
$dt-light-orange: #ffbd55;

// primary colors
$dt-red: #ed1c24;
$dark-gray: #333;
$medium-dark-gray: #424242;
$medium-gray: #666;
$hover-gray: #575757;
$medium-light-gray: #999;
$utility-gray: #5a5a5a;
$light-gray: #eaeaea;
$white: #fff;
$enough-light-gray: #f5f5f5;

// secondary colors
$blue: #00aeef;
$dark-blue: #3f6c92; // non-standard
$green: #39894b;
$medium-green: #52a240;
$bright-green: #62c54b;
$yellow: #e5d62a;
$orange: #ffa10d;

// Font Colors
$base-background-color: #fff;
$base-font-color: $dark-gray;
$primary-font-color: $dt-dark-gray;
$action-color: $blue;

// Border
$base-border-color: #eeeeee;
$medium-border-color: #cdcdcd;
$base-border: 1px solid $base-border-color;
$border-gray: #d2d2d2;
$border-fit-unsure: 1px solid $orange;
$border-fit: 1px solid $medium-green;
$border-not-fit: 1px solid $dt-red;

// Pit Pass designs use these new colors.

// grays
$tw-gray-01: #414042;
$tw-gray-02: #121213;

// greens
$tw-green-01: #268611;

// reds
$tw-red-01: #cf202f;
$tw-red-02: #ac1724;
$tw-red-03: #af0e14;

// blues
$tw-blue-01: #fafbfd;
$tw-blue-02: #e6ebf3;
$tw-blue-03: #667792;
$tw-blue-04: #3fb0ff;
$tw-blue-05: #064cb2;
$tw-blue-06: #063982;
$tw-blue-07: #b4dffd;

$red-50: #fcd8da;
$red-500: #e6121a;
$red-700: #b70e15;

$blue-50: #d5e5f8;
$blue-500: $dt-light-blue;
$blue-700: #1d62b1;

$green-50: #e2f2de;
$green-500: $medium-green;
$green-700: #407d32;

$gold-50: #fff0d9;
$gold-500: $orange;
$gold-700: #c87a00;

$violet-500: #a04ced;

$grey-50: #f8f8f8;
$grey-100: #efeff0;
$grey-200: #dddddf;
$grey-300: #c3c3c6;
$grey-400: #a9a9ae;
$grey-500: #98989d;
$grey-600: #75757c;
$grey-700: #545459;
$grey-800: #333336;
$grey-900: #1a1a1c;

$grey-b-50: #f1f2f5;
$grey-b-100: #dde1e7;
$grey-b-500: $tw-blue-03;
$grey-b-600: #515f74;

$black: $tw-gray-02;

//cec variants
$cec-dark-red: #b70e15;
$cec-medium-gray: #545459;
$cec-dark-blue: #0f3159;
$cec-medium-light-gray: #dddddf;
$cec-medium-light-gray-01: #f8f8f8;
$cec-medium-light-gray-02: #333336;
$cec-medium-light-gray-03: #c3c3c6;
$cec-light-gray: #efeff0;
$cec-medium-dark-gray: #98989d;
$cec-black: #1a1a1c;
