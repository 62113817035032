@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../shared/scss/dt-base';

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 21px;

  :global .fitment-notes-modal__table-container {
    overflow: auto;
  }

  :global .fitment-notes-modal__table-title {
    text-align: left;
    padding: 0 5px;
    line-height: rem(20);
    font-weight: 400;
  }
}

.return-button {
  margin-top: 35px;
}

.tabs {
  :global .tab-as-header__list {
    flex-wrap: nowrap;
    white-space: nowrap;
  }
}

.contents {
  padding: 12px 24px;
  border-radius: 3px;
  border: 1px solid $grey-500;
  font-size: rem(13);

  table {
    text-align: center;
    border-collapse: collapse;
  }

  pre {
    white-space: pre-wrap;
  }

  td,
  th {
    padding: 0 5px;
  }

  tr {
    td:first-child,
    th:first-child {
      padding-left: 0;
    }

    td:last-child,
    th:last-child {
      padding-right: 0;
    }
  }

  tr:nth-child(odd) {
    background-color: $dt-light-medium-gray;
  }

  tr:first-child {
    background-color: white;
  }
}

.vehicle-info-container {
  display: flex;
  flex-direction: column;
  line-height: 1.8rem;
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
  background-color: $dt-light-medium-gray;

  @include media($min-sm) {
    margin-right: auto;
    margin-left: 0;
    flex-direction: row;
    max-width: 600px;
  }
}

.vehicle-info-content {
  display: flex;
  text-align: left;
  font-weight: bold;
}

.vehicle-info-cell {
  text-align: left;
  font-weight: 700;
  width: 80px;
}

.vehicle-info-header-cell {
  text-align: left;
  font-weight: 400;
  width: 110px;
}

.vehicle-info-table {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  font-weight: 700;
  line-height: 1.8rem;
  font-size: 1rem;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;

  @include media($min-md) {
    border-bottom: 0;
    margin: 20px;
  }
}

.vehicle-info-table--bordered {
  border-bottom: 1px solid $dt-medium-light-gray;
  padding-bottom: 10px;

  @include media($min-md) {
    border-bottom: 0;
    padding-bottom: 0;
    border-right: 1px solid $dt-medium-light-gray;
  }
}

.vehicle-info-axle {
  color: $medium-gray;
  width: 80px;
  font-weight: bold;
}
